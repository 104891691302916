import React, { useState, useEffect } from 'react';
import "../src/tailwind.min.css";
import { App, Page, Navbar, Block, BlockTitle, Button, Card, Tabbar, TabbarLink, Icon, Fab, Progressbar, List, ListInput, ListItem, Sheet, Toolbar, Link, Toast, Range } from 'tailwind-mobile/react';
import { Plus, Person, BubbleRight, BubbleMiddleBottom, Bookmark, Hare, CloudDownload, Cloud, Clock, Barcode, Bolt, BoltSlash} from 'framework7-icons/react';

export default function MyApp() {

  const [data, setData] = useState({ data: []});
  const [uid, setUid] = useState("");
  const [refresh, setRefresh] = useState();
  const [time, setTime] = useState(Date.now());
  const [name, setName] = useState({ value: '', changed: false });
  const [hue, setHue] = useState({ value: '', changed: false });
  const [sheetOpened, setSheetOpened] = useState(false);

  const [toastMessage, setToastMessage] = useState("");

  const [toastLeftOpened, setToastLeftOpened] = useState(false);

  const openToast = (setter) => {
    // close other toast
    setToastLeftOpened(false);
    setter(true);
  };


  const saveUserData = function(){
    // save name locally
    localStorage.setItem('udarniki-username', name.value);
    localStorage.setItem('udarniki-hue', hue.value);


    fetch('https://api.rabe.app/react-set-user-username.php?username='+name.value+'&uid='+uid+'&hue='+hue, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
    body: "",
    })
    .then(res => res.json())
    .then((data) => {
    })
    .catch(console.log)
  };

  const onNameChange = (e) => {
    setName({ value: e.target.value, changed: true });
  };

  // API interface
  useEffect(() => {

    fetch('https://api.rabe.app/react-get-session.php', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
    body: "",
    })
    .then(res => res.json())
    .then((data) => {
      // console.log(data);
      setData({ data })

      if(data.sessionstart === "0000-00-00 00:00:00"){
       setToastMessage("Leitbild is currently offline");
         openToast(setToastLeftOpened);
       }else{
        setToastLeftOpened(false);
       }

      // console.log(this.state.todos)
    })
    .catch(console.log)
  },[refresh, time])


  // UID fetch
  useEffect(() => {

    console.log("INIT");

    let uid = localStorage.getItem("udarniki-uid");
    if(uid){ // user already known
      console.log("uid exists in localStorage: "+uid);
      setUid(uid);

      // try fetch username
      let uname = localStorage.getItem("udarniki-username");
      if(uname){
        console.log("username: "+uname);
        setName({ value: uname, changed: false });
      }

      fetch('https://api.rabe.app/react-set-user-lastactive.php?id='+uid, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        },
      body: "",
      })

    }else{ // new user, retrieve ID

      fetch('https://api.rabe.app/react-get-uid.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        },
      body: "",
      })
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        setUid( data );
        localStorage.setItem("udarniki-uid",data);
        // console.log(this.state.todos)
      })
      .catch(console.log)

    }
   
  },[])

  //auto reload
  useEffect(() => {
  const interval = setInterval(() => setTime(Date.now()), 1000);
  return () => {
    clearInterval(interval);
  };
  }, []);


  return (
      <App theme="ios">
      <Page>
      <Navbar title="UDARNIKI"></Navbar>
      {/* <BlockTitle>Time</BlockTitle>
      <Block strong>{time}</Block> */}
      
      <BlockTitle>Session</BlockTitle>
      <List>
        <ListItem
          media={<Person />}
          // link
          header="Username"
          title={name.value}
          // after="Edit"
        />
        <ListItem
          media={<Barcode />}
          // link
          header="UID"
          title={uid}
          // after="Edit"
        />
        <ListItem
          media={<Cloud />}
          // link
          header="State"
          title={data.data.state}
          // after="Edit"
          className={data.data.sessionstart === '0000-00-00 00:00:00' ? 'hidden' : 'visible'}
        />
        <ListItem
          media={<Clock />}
          // link
          header="Session Start"
          title={data.data.sessionstart}
          // after="Edit"
          className={data.data.sessionstart === '0000-00-00 00:00:00' ? 'hidden' : 'visible'}
        />


      </List>

      
      <Card>
      <div className={"content state-"+data.data.state}> </div>

      </Card>      

      

      <Tabbar labels={true}>
        <TabbarLink icon={<Icon
                ios={<Person className="w-7 h-7" />}
                material={<Plus className="w-6 h-6" />}
              />}>Dings</TabbarLink>
        <TabbarLink>Like</TabbarLink>
        <TabbarLink>Comment</TabbarLink>
      </Tabbar>

      <Fab
        className="fixed left-4-safe bottom-4-safe z-20"
        icon={<Bookmark />}
        colors={{ bg: data.data.sessionstart === '0000-00-00 00:00:00' ? 'hidden' : 'bg-black', activeBg: 'active:bg-gray-500' }}
      />

      <Fab
        className="fixed left-4-safe ios:top-15-safe material:top-18-safe z-20"
        colors={{ bg: data.data.sessionstart === '0000-00-00 00:00:00' ? 'hidden' :  'bg-blue-500', activeBg: 'active:bg-blue-600' }}
        icon={<CloudDownload />}
        onClick={() => setRefresh(!refresh)}
      />

      <Fab
        className="fixed left-1/2 transform -translate-x-1/2 ios:top-15-safe material:top-18-safe z-20"
        colors={{ bg: data.data.active === '1' ? 'bg-green-500' : 'bg-red-500', activeBg:  data.data.active === '1' ? 'bg-green-600' : 'bg-red-600' }}
        icon={data.data.active === '1' ? <Bolt /> : <BoltSlash/>}
        onClick={() => setRefresh(!refresh)}
      />


      <Fab
        className="fixed right-4-safe bottom-4-safe z-20"
        colors={{ bg: data.data.sessionstart === '0000-00-00 00:00:00' ? 'hidden' : 'bg-green-500', activeBg: 'active:bg-green-600' }}
        icon={<BubbleMiddleBottom />}
      />
      <Fab
        className="fixed right-4-safe ios:top-15-safe material:top-18-safe z-20"
        colors={{ bg: 'bg-black', activeBg: 'active:bg-gray-500' }}
        icon={<Person />}
        onClick={() => setSheetOpened(true)}
      />

      {/* <Fab
        className="fixed left-1/2 bottom-4-safe transform -translate-x-1/2 z-20"
        icon={<BubbleRight />}
        text="Comment"
        textPosition="after"
      /> */}
      <Block>
        <Progressbar colors={{ bg: 'bg-red-500' }} progress={0.5} />
      </Block>


      <Sheet
        className="pb-safe"
        opened={sheetOpened}
        onBackdropClick={() => setSheetOpened(false)}
      >
        <Toolbar top>
          <div className="left" />
          <div className="right">
            <Link toolbar onClick={() => setSheetOpened(false)}>
              Done
            </Link>
          </div>
        </Toolbar>
        <Block>
          <p>
            Feel free to choose a username and color to represent yourself in virtual space.
          </p>
          <BlockTitle>User</BlockTitle>
      {/* https://tailwind-mobile.com/react/list-input#list-input-components */}
      <List hairlines={true}>
        <ListInput
          label="Name"
          type="text"
          placeholder="Your name"
          value={name.value}
          media={<Person />}
          onChange={onNameChange}
        />

        <ListInput
          label="UID"
          type="text"
          placeholder="Your UID"
          media={<Hare />}
          value={uid}
          readOnly={true}
          disabled={true}
        />

        <ListInput
          label="Textarea"
          type="textarea"
          placeholder="Your message"
          media={<BubbleRight />}
          inputClassName="h-20 resize-none"
        />

        {/* <ListButton onClick={ () => saveUserData()}>Save Profile</ListButton> */}

        <ListItem>
        <Block>
        {hue.value}
        <div style={{
          backgroundColor: 'hsl('+hue+',50%,50%)',
          width: 50,
          height: 50
        }}></div>

        <Range
          value={hue}
          step={1}
          min = {0}
          max = {255}
          onChange={(e) => setHue(e.target.value)}
              />
        </Block>
        </ListItem>

      </List>
          <div className="mt-4">
            <Button onClick={() => {saveUserData(); setSheetOpened(false);}}>Save and Close</Button>
          </div>
        </Block>

        <Toast
          position="center"
          opened={toastLeftOpened}
          // button={
          //   <Button clear inline onClick={() => setToastLeftOpened(false)}>
          //     Close
          //   </Button>
          // }
        >
          <div className="flex-shrink">{toastMessage}</div>
        </Toast>

      </Sheet>
      </Page>
     </App>
  );
}


// import React, { Component } from 'react';



// class App extends Component {

//   state = {
//     data: []
//   }

//   componentDidMount() {
//     fetch('https://api.rabe.app/unity-get.php', {
//       method: 'POST',
//       mode: 'cors',
//       headers: {
//         'Content-Type': 'application/json',
//         'Cache-Control': 'no-cache'
//       },
//     body: "",
//     })
//     .then(res => res.json())
//     .then((data) => {
//       console.log(data);
//       this.setState({ data: data })
//       // console.log(this.state.todos)
//     })
//     .catch(console.log)
//   }


//   render() {
//     return (
//       <div className="container">
//       <div className="col-xs-12">
//       <h1>udarniki react</h1>
      
//       {this.state.data.map((item) => (
//         <div className="card">
//         id: {item.id}<br/>
//         <h1 className="highlight">state: {item.state}</h1>
//         active: {item.active}<br/>
//         debug: {item.debug}<br/>
//         usercount: {item.usercount}<br/>
//         </div>
//       ))}
//       </div>
//      </div>
//   );
//   }
// }
// export default App;